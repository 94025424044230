
import './xgj-modal.scss'
import {addClass, getStyle, hasClass, removeClass} from "../../utils/dom";
import getScrollBarWidth from "../../utils/scrollbar-width";

let scrollBarWidth;

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '50%'
    },
    xgjRadius: {
      type: String,
      default: '2px'
    },
    lockScroll: { //是否锁定body
      type: Boolean,
      default: true
    },
    closeModal: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    visibleSync: false,
    timer: null,
    withoutHiddenClass: true
  }),
  watch: {
    value(val) {
      if (val) {
        this.open();
      } else {
        this.close();
      }
    }
  },
  mounted() {
    // 组件渲染完成时，检查value是否为true，如果是，弹出popup
    this.value && this.open();
  },
  methods: {
    close() {
      if (this.lockScroll) {
        setTimeout(this.restoreBodyStyle, 200);
      }
      this.change('visibleSync', false)
    },
    open() {
      if (this.lockScroll) {
        this.withoutHiddenClass = !hasClass(document.body, 'el-popup-parent--hidden');
        if (this.withoutHiddenClass) {
          this.bodyPaddingRight = document.body.style.paddingRight;
          this.computedBodyPaddingRight = parseInt(getStyle(document.body, 'paddingRight'), 10);
        }
        scrollBarWidth = getScrollBarWidth();
        let bodyHasOverflow = document.documentElement.clientHeight < document.body.scrollHeight;
        let bodyOverflowY = getStyle(document.body, 'overflowY');
        if (scrollBarWidth > 0 && (bodyHasOverflow || bodyOverflowY === 'scroll') && this.withoutHiddenClass) {
          document.body.style.paddingRight = this.computedBodyPaddingRight + scrollBarWidth + 'px';
        }
        addClass(document.body, 'el-popup-parent--hidden');
      }
      this.change('visibleSync', true)
    },
    maskClick() {
      this.close()
    },
    restoreBodyStyle() {
      if (this.withoutHiddenClass) {
        document.body.style.paddingRight = this.bodyPaddingRight;
        removeClass(document.body, 'el-popup-parent--hidden');
      }
      this.withoutHiddenClass = true;
    },
    change(param1, status) {
      this[param1] = status;
      if(status) {
        this.timer = setTimeout(() => {
          this.$emit(status ? 'open' : 'close');
        }, 50);
      } else {
        this.timer = setTimeout(() => {
          this.$emit(status ? 'open' : 'close');
        }, 300);
      }
    },
    _renderMask() {
      if (this.closeModal) {
        return (
          <div class="modal-overlay" onClick={() => this.maskClick()}></div>
        )
      } else {
        return (
          <div class="modal-overlay"></div>
        )
      }
    }
  },
  render() {
    if (this.visibleSync) {
      return (
        <div class="xgj-modal__wrapper">
          <transition name="fade" appear>
            {this._renderMask()}
          </transition>
          <transition name="pop" role="dialog" appear>
            <div class="xgj-modal" style={{'border-radius':this.xgjRadius, 'width': this.width}}>
              {this.$slots.default}
            </div>
          </transition>
        </div>
      )
    }
  }
}
