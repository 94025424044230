import log from './util.log'
import cookies from './util.cookies'

const util = {
  log,
  cookies
}

util.padLeftZero = function (str) {
  return ('00' + str).substr(str.length)
};

/**
 * @description 更新标题
 * @param {String} title 标题
 */
util.title = function (titleText) {
  const processTitle = process.env.VUE_APP_TITLE || 'eWorship'
  window.document.title = `${processTitle}${titleText ? ` | ${titleText}` : ''}`
};

/**
 * @description 打开新页面
 * @param {String} url 地址
 */
util.open = function (url) {
  var a = document.createElement('a')
  a.setAttribute('href', url)
  a.setAttribute('target', '_blank')
  a.setAttribute('id', 'd2admin-link-temp')
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(document.getElementById('d2admin-link-temp'))
};

/**
 * 格式化日期
 * 兼容ie ios 日期nan问题
 *
 * @param date
 * @param fmt 默认格式为 yyyy-MM-dd
 * @return {*}
 */
util.formatDate = function (date, fmt) {
  if (date == null || date == '')
    return null;

  date = util.getNewDate(date);

  fmt = fmt ? fmt : 'yyyy-MM-dd';
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : util.padLeftZero(str))
    }
  }
  return fmt
};

util.getNewDate = function (date) {
  if (date == null || date == "") {
    date = new Date();
  }
  if (typeof date === 'string') {
    date = new Date(date.replace(/-/g, '/').replace(/T|Z/g, ' ').trim());
  } else if (typeof date === 'object') {
    date = new Date(date);
  }
  return date;
};

/**
 * 字符串的下划线格式转驼峰格式，eg：hello_world => helloWorld
 * @param s
 */
util.underline2Hump = function(s) {
  return s.replace(/_(\w)/g, function (all, letter) {
    return letter.toUpperCase();
  })
};


/**
 * 字符串的驼峰格式转下划线格式，eg：helloWorld => hello_world
 * @param s
 * @returns {string}
 */
util.hump2Underline = function(s) {
  return s.replace(/([A-Z])/g, '_$1').toLowerCase();
};

/**
 * JSON对象的key值转换为驼峰式
 * @param obj
 */
util.jsonToHump = function(obj) {
  var util = this;
  if (obj instanceof Array) {
    obj.forEach(function (v, i) {
      util.jsonToHump(v);
    })
  } else if (obj instanceof Object) {
    Object.keys(obj).forEach(function (key) {
      var newKey = util.underline2Hump(key);
      if (newKey !== key) {
        obj[newKey] = obj[key];
        delete obj[key];
      }
      util.jsonToHump(obj[newKey]);
    })
  }
  return obj
};

/**
 * JSON对象的key值转换为下划线格式
 * @param obj
 */
util.jsonToUnderline = function(obj) {
  if (obj instanceof Array) {
    obj.forEach(function (v, i) {
      util.jsonToUnderline(v);
    })
  } else if (obj instanceof Object) {
    Object.keys(obj).forEach(function (key) {
      var newKey = util.hump2Underline(key);
      if (newKey !== key) {
        obj[newKey] = obj[key];
        delete obj[key];
      }
      util.jsonToUnderline(obj[newKey]);
    })
  }
};

/**
 * 克隆一个新对象，采用json转换模式
 * @param obj
 * @returns {any}
 */
util.cloneObject = function(obj) {
  try{
    var json = JSON.stringify(obj);
    return JSON.parse(json);
  }catch (e) {
    console.log('cloneObject error: ', e);
  }
};


/**
 * 获取uuid
 * @param null
 * @returns String
 */
util.generateUUID = function () {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now(); //use high-precision timer if available
  }
  let uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}

export default util
