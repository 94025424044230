import './header.scss'

import Logindialog from '@/plugins/login/main'
import { mapState } from "vuex";
import _ from 'lodash'

export default {
  data: () => ({
    keyword: null,
    displayNav: false
  }),
  computed: {
    ...mapState({
      token: state => state.user.token,
      userInfo: state => state.user.userInfo
    })
  },
  methods: {
    keyUpChange(e) {
      if ( e.key === 'Enter') {
        if (this.keyword) {
          this.$store.commit('updateSearchWord', this.keyword);
          if (this.$route.path !== '/search') {
            this.$router.push({path: '/search'})
          }
          this.keyword = null
        } else {
          this.$message.error('搜索关键字不能为空!')
        }
      }
    },
    toWorship() {
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = `${process.env.VUE_APP_WORSHIP_HOST}${process.env.VUE_APP_WORSHIP_PAGE}`;
      a.click()
    },
    onFocus() {
      this.displayNav = true;
    },
    onBlur() {
      this.displayNav = false;
    },
    login() {
      Logindialog({visible: true})
    },
    resetLogin() {
      this.$confirm('正在退出登录', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$bus.$emit('fromLogin');
        this.$store.commit('resetLogin')
      }).catch(() => {

      });
    },
    _renderUserView() {
      if (!_.isEmpty(this.userInfo)) {
        return (
          <div class="login-head">
            <el-popover
              placement="bottom"
              width="360"
              trigger="hover"
              >
              <img slot="reference" src={this.userInfo.head_img_url}/>
              <div class="headerPop">
                <div class="tips"><span>再忙，也要记得祭奠亲人</span><i onClick={() => this.resetLogin()} class="login-out sea-iconfont">&#xe743;</i></div>
                <div class="userInfo">
                  <div><img src={this.userInfo.head_img_url} /></div>
                  <div class="nick_name">{this.userInfo.nick_name}</div>
                  <div class="about">
                    <span>账号绑定</span>
                    <span class={`sea-iconfont ${_.isEmpty(this.userInfo) ? '' : 'select'}`}>&#xe654;</span>
                    <span class={`sea-iconfont ${this.userInfo.mobile ? 'select' : ''}`}>&#xe676;</span>
                  </div>
                </div>
                <div class="more-memorial"></div>
                <div class="set">
                  <router-link to="/personal-view/personal-center">
                    <i class="sea-iconfont">&#xe61a;</i>
                    <div>个人信息</div>
                  </router-link>
                  <router-link to="/personal-view/personal-schedule">
                    <i class="sea-iconfont">&#xe610;</i>
                    <div>办件进程</div>
                  </router-link>
                  <router-link to="/personal-view/personal-back">
                    <i class="sea-iconfont">&#xe65b;</i>
                    <div>反馈回复</div>
                  </router-link>
                  <router-link to="/personal-view/historical-changes">
                    <i class="sea-iconfont">&#xe6b7;</i>
                    <div>历史修改记录</div>
                  </router-link>
                </div>
              </div>
            </el-popover>
          </div>
        )
      } else {
        return (
          <div class="login-head">
            <img onClick={() => this.login()} src={require('../../assets/images/not-log-in.png')}/>
          </div>
        )
      }
    }
  },
  render() {
    return (
      <div class="head">
        <div class="header" style="box-shadow: rgba(25, 24, 40, 0.35) 0px 28px 50px;">
          <div class="head-inner">
            <div class="logo">
              <router-link to={{ path: '/' }}><img src={require('../../assets/images/header-logo.png')} /></router-link>
            </div>
            <div class={`nav-gation ${this.displayNav ? 'nav-gation-none' : ''}`}>
              <ul class="nav">
                <li>
                  <span class={`${this.$route.meta.select === 'index' ? 'nav-active' : ''}`}><router-link to={{ path: '/' }}>首页</router-link></span>
                </li>
                <li>
                  <span class={`${this.$route.meta.select === 'news' ? 'nav-active' : ''}`}><router-link to={{ path: '/news/news-list' }}>新闻资讯</router-link></span>
                </li>
                <li>
                  <span class={`${this.$route.meta.select === 'policy' ? 'nav-active' : ''}`}><router-link to={{ path: '/policy/policy-list' }}>政策法规</router-link></span>
                </li>
                <li>
                  <span class={`${this.$route.meta.select === 'sea' ? 'nav-active' : ''}`}><router-link to={{ path: '/sea/sea-index' }}>海葬服务</router-link></span>
                </li>
                <li>
                  <span onClick={() => this.toWorship()}><router-link to>网上祭祀</router-link></span>
                </li>
                <li>
                  <span class={`${this.$route.meta.select === 'call' ? 'nav-active' : ''}`}><router-link to={{ path: '/call/online' }}>在线客服</router-link></span>
                </li>
                <li>
                  <span class={`${this.$route.meta.select === 'about' ? 'nav-active' : ''}`}><router-link to={{ path: '/about/company' }}>关于我们</router-link></span>
                </li>
              </ul>
            </div>
            <div class="search-input">
              <div class="inputs">
                <input type="text" class={`${this.displayNav ? 'display-nav' : ''}`} v-model={this.keyword} onFocus={() => {this.onFocus()}} onBlur={() => {this.onBlur()}} onkeyup={ e => this.keyUpChange(e)}/>
                <i class="sea-iconfont icon-sousuo" onClick={() => this.keyUpChange({key: 'Enter'})}></i>
              </div>
            </div>
            <div class="login">
              {this._renderUserView()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
