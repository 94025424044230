<template>
  <div id="app">
    <section class="el-container">
      <main class="el-main defaultnew">
        <!--header 部分-->
        <web-header></web-header>
        <!--header 部分-->
        <router-view v-if="isRouterAlive"></router-view>

        <!--footer 部分-->
        <web-footer :marginTop="footerTop"></web-footer>
        <!--footer 部分结束-->
      </main>
    </section>
  </div>
</template>

<script>
  var _hmt = _hmt || [];
  (function () {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?4f2780c358645cd074091b7011f66531";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();

  import { mapState } from 'vuex'

  export default {
    name: 'App',
    computed: {
      ...mapState({
        footerTop: state => state.Sea.footerTop
      })
    },
    data() {
      return {
        isRouterAlive: true
      }
    },
    created() {
      const that = this;
      async function loading () {
        await that.$store.dispatch('getNavColumn')
        await that.$store.dispatch('getOrgInfo')
        await that.$store.dispatch('getAboutUs')
      }
      loading()
    },
    mounted() {
      this.$bus.$on('fromLogin', () => {
        this.reload()
      })
    },
    destroyed() {
      this.$bus.$off('fromLogin')
    },
    methods: {
      reload() {
        this.isRouterAlive = false;
        this.$nextTick(() => {
          this.isRouterAlive = true
        })
      },
    }
  }
</script>

<style lang="scss">

</style>
