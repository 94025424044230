export default ({ service }) => ({
  infor: {
    /**
     * 查询消息列表
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    queryInforList(data) {
      return service.get('/api/infor/list', {params: data});
    }
  }
})
