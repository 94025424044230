
import './xgj-notice.scss'

export default {
  props: {
    options: {
      type: Object,
      default() {
        return {
          text: '默认'
        };
      }
    }
  },
  data: () => ({
    speed: '50', // 速度（单位px/s）
    backWidth: '', // 父级宽度
    backHeight: '', // 父级高度
    wordLength: '', // 文本长度
    state: 1,
    firstAnimationTime: '', // 状态一动画效果
    secondAnimationTime: '', // 状态二动画效果
    data: ''
  }),
  created() {
    this.speed = this.options.speed,
    this.data = this.options
  },
  methods: {
    // 获取数据
    getData() {
      let style = document.styleSheets[0];
      let text = this.$refs.text;
      let back = this.$refs.back;
      this.backWidth = back.offsetWidth;
      this.backHeight = back.offsetHeight;
      text.style.lineHeight = this.backHeight + 'px';
      this.wordLength = text.offsetWidth;
      this.ComputationTime(); // 计算时间
      style.insertRule(
        `@keyframes firstAnimation {0%   {left:0px;}100%  {left:-${this.wordLength}px;}}`
      );
      style.insertRule(
        `@keyframes secondAnimation {0%   {left:${this.backWidth}px;}100%  {left:-${this.wordLength}px;}}`
      );
      setTimeout(res => {
        this.changeState();
      }, this.data.delay);
    },
    // 用速度计算时间（想要保持速度一样，2种状态时间不同需算出）
    ComputationTime() {
      this.firstAnimationTime = this.wordLength / this.speed;
      this.secondAnimationTime =
        (this.wordLength + this.backWidth) / this.speed;
    },
    // 根据状态切换动画
    changeState() {
      let text = this.$refs.text;
      if (this.state == 1) {
        text.style.animation = `firstAnimation ${this.firstAnimationTime}s linear`;
        this.state = 2;
      } else {
        text.style.animation = `secondAnimation ${this.secondAnimationTime}s linear infinite`;
      }
    },
    Listener() {
      let text = this.$refs.text;
      // console.log(text);
      text.addEventListener(
        'animationend',
        res => {
          this.changeState();
        },
        false
      );
    },
    _renderDataIcon() {
      if (this.data.icon) {
        return (
          <img v-if="data.icon" style={{height:this.data.iconSize ? this.data.iconSize : '16px', width: this.data.iconSize ? this.data.iconSize : '16px'}} src={this.data.icon} alt="" />
        )
      }
    }
  },
  mounted() {
    this.Listener();
    setTimeout(res => {
      this.getData();
    }, 100);
  },
  render() {
    return (
      <transition name="fade">
        <div class="xgj-noticebar" style={{backgroundColor : this.data.backround}}>
          <div style="margin-left:5px"></div>
          {this._renderDataIcon()}
          <div style="margin-right:5px"></div>
          <div ref="back" class="back">
            <span ref="text" style={{fontSize: this.data.size? this.data.size : '16px', color : this.data.color ? this.data.color:'#f60' }} class="text">
              { this.data.text?this.data.text:'通知内容' }
            </span>
          </div>
        </div>
      </transition>
    )
  }
}
