import api from '@/api'
import { errorCreate, parse } from '@/api/tools'
import util from "@/utils/util";

const $api = api

const userModules = {
  state: {
    partnerId: process.env.VUE_APP_LIMIT_AREA_ID,
    token: util.cookies.get(),
    searchWord: null,
    userInfo: {},
    aboutUs: {},
    orgInfo: {}
  },
  getters: {
    userInfo: state => state.userInfo
  },
  mutations: {
    updateAboutUs(state, about) {
      state.aboutUs = about
    },
    updateOrgInfo(state, orgInfo) {
      state.orgInfo = orgInfo
    },
    updateUserInfo(state, userInfo) {
      state.userInfo = userInfo
    },
    updateSearchWord(state, searchWord) {
      state.searchWord = searchWord
    },
    updateToken(state, token) {
      state.token = token
    },
    resetLogin(state) {
      document.cookie = "yx-ticket=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/; domain=.syhz.org.cn";
      util.cookies.remove('yx-ticket')
      state.userInfo = {}
      state.token = util.cookies.get()
    }
  },
  actions: {
    async getAboutUs(context) { //获取关于我们资料存储
      await $api.website.getOneContent({partnerId: context.state.partnerId, columnId: 'cef2ed37be84407ca581fe3c7fabbd2a'})
        .then( dates => {
           context.commit('updateAboutUs', dates)
        }).catch( err => {
           //errorCreate(`获取关于我们: ${err}`)
        })
    },
    async getOrgInfo(context) {
      await $api.website.getCommonPartnerDetail({partnerId: context.state.partnerId})
        .then( dates => {
           context.commit('updateOrgInfo', dates)
        }).catch( err => {
           //errorCreate(`获取单位信息: ${err}`)
        })
    },
    async getCurrentUser(context) {
      await $api.system.getCurrentUser()
        .then( dates => {
          context.commit('updateUserInfo', dates)
        }).catch( err => {
           //errorCreate(`获取用户信息: ${err}`)
        })
    }
  }
}

export default userModules
