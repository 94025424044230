const global = {
  ATTACH_BUSI_TYPE: {
    FULL: 1, //全屏2:1

    BANNER_WEIXIN_INDEX: 1001, //微信端首页
    HALL_HEAD: 1002, //纪念馆头像
    PARTNER_MAIN: 1003, //合作商主图
    PARTNER_LOGO: 1004, //合作商logo
    DEAD_HEAD: 1005, //逝者头像
    DEAD_CODE_HEAD: 1006, //逝者祭拜码头像
    PARTNER_WX_QR_CODE: 1007, //合作商微信二维码图

    /**********  模板 **********/
    TEMPL_INDEX_BG: 1008, //首页背景
    TEMPL_INDEX_BG_CATEGORY: 1050, //首页背景分类
    TEMPL_INDEX_PF: 1009, //首页相框
    TEMPL_INDEX_PF_CATEGORY: 1051, //首页相框分类

    TEMPL_INDEX_FUN_INTRO: 1010, //首页功能图-资料
    TEMPL_INDEX_FUN_HONOUR: 1011, //首页功能图-荣誉
    TEMPL_INDEX_FUN_BIOGR: 1012, //首页功能图-传记
    TEMPL_INDEX_FUN_EULOGY: 1013, //首页功能图-悼文
    TEMPL_INDEX_FUN_MEDIA: 1014, //首页功能图-音容笑貌
    TEMPL_INDEX_FUN_FRIEND: 1015, //首页功能图-亲友圈
    TEMPL_INDEX_FUN_WORSHIP: 1016, //首页功能图-祭拜记录
    TEMPL_INDEX_FUN_TEMPL: 1017, //首页功能图-模板
    TEMPL_INDEX_FUN_AGENT: 1018, //首页功能图-代祭扫
    TEMPL_INDEX_FUN_SHOP: 1019, //首页功能图-线上商城

    TEMPL_INTRO_BG: 1020, //简介背景
    TEMPL_INTRO_HF: 1021, //简介头像外框
    TEMPL_BIOGR_BG: 1022, //传记背景
    TEMPL_BIOGR_TL: 1023, //传记时间线图
    TEMPL_BIOGR_TIME_POINT: 1047, //传记时间点
    TEMPL_BIOGR_TIME_BG: 1024, //传记时间底板背景
    TEMPL_HONOUR_BG: 1025, //荣誉背景
    TEMPL_HONOUR_TITLE_BG: 1026, //荣誉标题背景
    TEMPL_HONOUR_SIDE_ICON1: 1027, //荣誉装饰图标1
    TEMPL_HONOUR_SIDE_ICON2: 1045, //荣誉装饰图标2
    TEMPL_HONOUR_SIDE_ICON3: 1046, //荣誉装饰图标3
    TEMPL_HONOUR_CONTENT_BG: 1028, //荣誉内容背景
    TEMPL_EULOGY_LIST_BG: 1029, //悼文列表背景
    TEMPL_EULOGY_LIST_TITLE_BG: 1030, //悼文列表标题图标背景
    TEMPL_EULOGY_BG: 1031, //悼文背景
    TEMPL_EULOGY_SHAR: 1048, //悼文分享图标


    GOODS_MAIN: 1040, //商品主图
    GOODS_ADVERTY: 1041, //商品产品图

    WORSHIP_GOODS_THUMBNAIL: 1042, //祭拜品缩略图
    WORSHIP_GOODS_PLAY: 1043, //祭拜品播放图
    WORSHIP_CATEGORY: 1044, //祭拜品分类图

    BIOGRAPHY: 1049, //传记图
    WORSHIP_HEAD: 1052 //祭拜头像

  },

  HALL_TYPE: {
    NORMAL: 0,  //普通
    MARTYR: 1, //烈士
    CELEBRITY: 2 //名人
  },

  TICKET: {
    NAME: 'yx-ticket',
    DOMAIN: process.env.VUE_APP_DOMAIN,
    EXPIRES: 2 * 3600,
    PATH: '/'
  },

  KEY: {
    DES: '1234567890',
    AES: '1234567890'
  },

  APP: {
    NAME: 'yxappname'
  },

  ORDER_TYPE: {
    COIN: '1', //孝豆充值
    WORSHIP_CODE: '2', // 祭拜码生成,
    WORSHIP_ENTITY_CODE: '3', //祭拜实体码购买
    HALL_TEMPLATE: '4',//纪念馆模板购买
    STORE_SPACE: '5'  //云存储空间扩容
  }


};

export default global;
