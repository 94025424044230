
import './xgj-empty.scss'

export default {
  props: {
    title: {
      type: String,
      default: '暂无相关数据'
    },
    color: {
      type: String,
      default: '#969799'
    },
    height: {
      type: String,
      default: '100%'
    }
  },
  data: () => ({

  }),
  render() {
    return(
      <div class="xgj-empty-main" style={'height:' + this.height}>
        <div class="xgj-empty-images">
          <img class="xgj-empty-images" src={require('./xgj-empty-icon.svg')} />
          <div class="xgj-empty-description" style={'color:' + this.color}>{this.title}</div>
        </div>
      </div>
    )
  }
}
