import Vue from 'vue'
import Vuex from 'vuex'
import { Message } from 'element-ui'
import user from "./modules/user";
import Sea from './modules/sea'

Vue.use(Vuex)

export default new Vuex.Store({
  mutations: {
    showMessage(state, options) {
      Message({
        message: options.message,
        type: options.type || 'error',
        duration: options.duration || 2 * 1000
      })
    }
  },
  modules: {
    user,
    Sea
  }
})
