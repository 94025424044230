import axios from 'axios'
import $store from '@/store'
import qs from 'qs';
import Adapter from "axios-mock-adapter"
import { get } from 'lodash'
import global from '@/utils/const.define'
import util from '@/utils/util'
import { errorLog, errorCreate } from './tools'


/**
 * @description 创建请求实例
 */
function createService () {
  // 创建一个 axios 实例
  const service = axios.create({
    timeout: 120 * 1000 // 请求超时时间
  })

  // 请求拦截
  service.interceptors.request.use(
    config => {
      // 在请求发送之前做一些处理，统一添加ticket, appName参数
      config.headers[global.TICKET.NAME] = util.cookies.get();
      config.headers[global.APP.NAME] = process.env.VUE_APP_MP_NAME;

      if (config.method === 'post' && config.headers['Content-Type'] === 'multipart/form-data') {
        return config
      }

      config.headers['X-Requested-With'] = 'XMLHttpRequest'
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded'

      if (config.method !== 'get') {
        config.data = qs.stringify(config.data, {allowDots: true});
      }

      return config
    },
    error => {
      // 发送失败
      console.log(error)
      return Promise.reject(error)
    }
  )
  // 响应拦截
  service.interceptors.response.use(
    response => {
      // dataAxios 是 axios 返回数据中的 data
      const dataAxios = response.data
      // 这个状态码是和后端约定的
      const code  = dataAxios.status
      // 根据 code 进行判断
      if (code === undefined) {
        // 如果没有 code 代表这不是项目后端开发的接口
        return dataAxios
      } else {
        // 有 code 代表这是一个后端接口 可以进行进一步的判断
        switch (code) {
          case 200:
            // [ 示例 ] code === 200 代表没有错误
            if (response.config.url == '/website-common/system/verifycode') {
              return dataAxios
            } else {
              return dataAxios.data
            }
          case 401:
            // [ 示例 ] 其它和后台约定的 code
            // window.location.href = `${process.env.VUE_APP_PAGE_HOST}/login`
            // errorCreate(`[ code: 401 ] ${dataAxios.msg}: ${response.config.url}`)
            $store.commit('resetLogin')
            break
          default:
            // 不是正确的 code
            errorCreate(`${dataAxios.msg}: ${response.config.url}`)
            break
        }
      }
    },
    error => {
      const status = get(error, 'response.status')
      switch (status) {
        case 400: error.message = '请求错误'; break
        case 401: error.message = '未授权，请登录'; break
        case 403: error.message = '拒绝访问'; break
        case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
        case 408: error.message = '请求超时'; break
        case 500: error.message = '服务器内部错误'; break
        case 501: error.message = '服务未实现'; break
        case 502: error.message = '网关错误'; break
        case 503: error.message = '服务不可用'; break
        case 504: error.message = '网关超时'; break
        case 505: error.message = 'HTTP版本不受支持'; break
        default: break
      }
      errorLog(error)
      return Promise.reject(error)
    }
  )
  return service
}


// 用于真实网络请求的实例和请求方法
export const service = createService()

// 用于模拟网络请求的实例和请求方法
export const serviceForMock = createService()

// 网络请求数据模拟工具
export const mock = new Adapter(serviceForMock)
