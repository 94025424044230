import './footer.scss'

import { mapState } from 'vuex'

export default {
  props: {
    marginTop: {
      type: String,
      default: '0px'
    }
  },
  computed: {
    ...mapState({
      orgInfo: state => state.user.orgInfo
    })
  },
  methods: {
    toLinks(url) {
      let a = document.createElement('a');
      a.target = '_blank';
      a.href = url;
      a.click()
    }
  },
  render() {
    if (this.orgInfo.wx_image) {
      return (
        <div class="footer" style={'margin-top:' + this.marginTop}>
          <div class="footer-about">
            <div class="about-content">
              <div class="qcode-wx">
                <img class="code" src={this.orgInfo.wx_image.url} />
                <div class="qcode-name">沈阳海葬微信公众号</div>
              </div>
              <div class="qcode-dy">
                <img class="code" src={this.orgInfo.thrill_image.url} />
                <div class="qcode-name">沈阳海葬抖音号</div>
              </div>
              <div class="lxwm">
                <div class="htitle">联系我们</div>
                <div class="lxwm-item">电话:{this.orgInfo.telephone}</div>
                {/*<div class="lxwm-item">邮箱:{this.orgInfo.email}</div>*/}
                <div class="lxwm-item">地址:{this.orgInfo.address}</div>
              </div>
              <div class="my-links">
                <div class="htitle">友情链接</div>
                <div class="htitle-item" onClick={() => this.toLinks('http://mzj.shenyang.gov.cn/')}>沈阳市民政局</div>
                <div class="htitle-item" onClick={() => this.toLinks('http://www.sybzfww.com/')}>沈阳市殡葬协会</div>
              </div>
              <div class="footer-logo">
                <img src={require('../../assets/images/footer-logo.png')} />
              </div>
            </div>
          </div>
          <div class="footer-copyright">
            <div class="copyright-content">
              <div class="footer-href">
                <a target="_blank" href="https://beian.miit.gov.cn/">辽ICP备2020011438号-1</a>
              </div>
              <div>Copyright@2020 www.syhz.org Allright Reserved</div>
            </div>
          </div>
        </div>
      )
    }
  }
}
