export default ({service}) => ({
  system: {
    /**
     * 获取当前用户信息
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getCurrentUser() {
      return service.get('/website-common/rbac/user/current')
    },

    /**
     * 轮询code
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    pollingCode(data) {
      return service.post('/website-common/rbac/website/login4code', data);
    },

    /**
     * 获取短信验证码
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getVerifycode(data) {
      return service.post('/website-common/system/verifycode', data);
    },
  }
})
