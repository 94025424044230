import xgjEmpty from '@/components/empty/xgj-empty'
import xgjModal from '@/components/modal/xgj-modal'
import xgjNotice from '@/components/notice/xgj-notice'
import webHeader from "@/components/header/header";
import webFooter from "@/components/footer/footer";

function plugin(Vue) {
  if (plugin.installed) {
    return
  }
  Vue.component('xgjEmpty', xgjEmpty)
  Vue.component('xgjModal', xgjModal)
  Vue.component('webHeader', webHeader)
  Vue.component('webFooter', webFooter)
  Vue.component('xgjNotice', xgjNotice)
}

export default plugin
