import Vue from 'vue'
import Main from './login'
const LogindialogConstructor = Vue.extend(Main);
let instance;

const Logindialog = function (options) {
  if (Vue.prototype.$isServer) return;

  options.close = function() {
    Logindialog.close();
  };

  instance = new LogindialogConstructor({
    data: options
  });

  instance.$mount();
  document.body.appendChild(instance.$el);

  return instance;
};

Logindialog.close = function() {
  document.body.removeChild(instance.$el)
};

export default Logindialog
