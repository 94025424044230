import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store/index";
import ElementUI from 'element-ui';
import api from '@/api'
import 'element-ui/lib/theme-chalk/index.css'
import GL_Component from '@/plugins/globalComponents'
import VideoPlayer from 'vue-video-player'
import './assets/style/iconfont.css'
import animated from 'animate.css'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'


let $bus = new Vue()
Vue.prototype.$bus = $bus
Vue.prototype.$api = api
Vue.prototype.$store = store
Vue.prototype.mainBody = function(msg) {
  var reg = /<[^>]+>/g;
  if (reg.test(msg)){
    msg = msg.replace(/<\/?[^>]*>/g, '');
    msg = msg.replace(/[|]*\n/, '');
    msg = msg.replace(/&npsp;/ig, '');
    return msg;
  }else {
    return msg;
  }
}

Vue.use(animated)
Vue.use(ElementUI)
Vue.use(GL_Component)
Vue.use(VideoPlayer)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
