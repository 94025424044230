export default ({ service }) => ({
  hall: {
    /**
     * 查询系统推荐的馆
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    queryRecomHall(data) {
      return service.get('/api/hall/recom', {params: data});
    },

    /**
     * 获取传记列表
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getBiographies(data) {
      return service.get('/api/hall/biography/list', {params: data});
    },

    /**
     * 获取传记访问数
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getBiographyVisitNum(data) {
      return service.get('/api/hall/biography/visit-num', {params: data});
    },

    /**
     * 新增传记访问量
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    saveBiographyVisit(data) {
      return service.post('/api/hall/biography/visit', data);
    },

    /**
     * 获取纪念悼文列表
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getEulogies(data) {
      return service.get('/api/hall/eulogy/list', {params: data});
    },

    /**
     * 获取纪念馆的逝者信息
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getDeadByHall(data) {
      return service.get('/api/dead/detail4hall', {params: data});
    },
  }
})
