import Cookies from 'js-cookie'
import global from "./const.define";

const cookies = {};

/**
 * @description 存储 cookie 值
 * @param {String} value cookie value
 * @param {Object} setting cookie setting
 */
cookies.set = function (value = '', cookieSetting = {}) {
  let currentCookieSetting = {
    expires: global.TICKET.EXPIRES,
    domain: global.TICKET.DOMAIN,
    path: global.TICKET.PATH
  };
  Object.assign(currentCookieSetting, cookieSetting)
  Cookies.set(global.TICKET.NAME, value, currentCookieSetting)
}

/**
 * @description 拿到 cookie 值
 * @param {String} name cookie name
 */
cookies.get = function () {
  return Cookies.get(global.TICKET.NAME)
}

/**
 * @description 拿到 cookie 全部的值
 */
cookies.getAll = function () {
  return Cookies.get()
}

/**
 * @description 删除 cookie
 * @param {String} name cookie name
 */
cookies.remove = function (name = global.TICKET.NAME) {
  return Cookies.remove(`${name}`)
}

export default cookies
