const hallView = () => import('@/views/hall/hall-view.vue')
const hallIndex = () => import('@/views/hall/hall-index.vue')
const hallCreate = () => import('@/views/hall/hall-create.vue')
const hallPersonal = () => import('@/views/hall/hall-personal/hall-personal.vue')
const hallRelated = () => import('@/views/hall/hall-personal/hall-related.vue')
const hallInteract = () => import('@/views/hall/hall-personal/hall-interact.vue')
const systemMessages = () => import('@/views/hall/hall-personal/system-messages.vue')
const worshipView = () => import('@/views/hall/hall-worship/worship-view.vue')
const worshipVisitRecord = () => import('@/views/hall/hall-worship/worship-visit-record.vue')
const worshipVisitBiography = () => import('@/views/hall/hall-worship/worship-visit-biography.vue')
const worshipVisitEulogy = () => import('@/views/hall/hall-worship/worship-visit-eulogy.vue')
const worshipVisitLife = () => import('@/views/hall/hall-worship/worship-visit-life.vue')
const worshipFriendsCircle = () => import('@/views/hall/hall-worship/worship-friends-circle.vue')
const worshipSmileVoice = () => import('@/views/hall/hall-worship/worship-smile-voice.vue')
const worshipHonorDeeds = () => import('@/views/hall/hall-worship/worship-honor-deeds.vue')
const palaceHall = () => import('@/views/hall/palace-hall.vue')

export default [
  {
    path: '/hall',
    name: 'hall-view',
    component: hallView,
    children: [
      {path: 'hall-index', name: 'hall-index', meta: {title: '网上祭祀-首页'}, component: hallIndex},
      {path: 'hall-create', name: 'hall-create', meta: {title: '网上祭祀-建馆'}, component: hallCreate},
      {path: 'palace-hall', name: 'palace-hall', meta: {title: '网上祭祀-殿堂'}, component: palaceHall},
      {
        path: 'personal',
        name: 'hall-personal',
        component: hallPersonal,
        children: [
          {path: 'hall-related', name: 'hall-related', meta: {title: '个人中心-我的相关馆'}, component: hallRelated},
          {path: 'hall-interact', name: 'hall-interact', meta: {title: '个人中心-互动消息'}, component: hallInteract},
          {path: 'system-messages', name: 'system-messages', meta: {title: '个人中心-系统消息'}, component: systemMessages}
        ]
      },
      {
        path: 'worship',
        name: 'worship-view',
        component: worshipView,
        children: [
          {path: 'visit-record', name: 'worship-visit-record', meta: {title: '祭拜-祭拜记录'}, component: worshipVisitRecord},
          {path: 'visit-biography', name: 'worship-visit-biography', meta: {title: '祭拜-传记'}, component: worshipVisitBiography},
          {path: 'visit-eulogy', name: 'worship-visit-eulogy', meta: {title: '祭拜-悼文'}, component: worshipVisitEulogy},
          {path: 'visit-life', name: 'worship-visit-life', meta: {title: '祭拜-生平'}, component: worshipVisitLife},
          {path: 'friends-circle', name: 'worship-friends-circle', meta: {title: '祭拜-亲友圈'}, component: worshipFriendsCircle},
          {path: 'smile-voice', name: 'worship-smile-voice', meta: {title: '祭拜-音容'}, component: worshipSmileVoice},
          {path: 'honor-deeds', name: 'worship-honor-deeds', meta: {title: '祭拜-荣誉'}, component: worshipHonorDeeds}
        ]
      }
    ]
  }
]
