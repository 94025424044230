import Vue from 'vue'
import VueRouter from 'vue-router'
import $store from '@/store'
import _ from 'lodash'
import module from "./module";
import util from "@/utils/util";
import Logindialog from '@/plugins/login/main'

Vue.use(VueRouter)

const notFind = () => import('@/views/not-find.vue')
const index = () => import('@/views/index.vue')
const search = () => import('@/views/search.vue')
const news = () => import('@/views/news/news.vue')
const newsList = () => import('@/views/news/news-list.vue')
const policy = () => import('@/views/policy/policy.vue')
const policyList = () => import('@/views/policy/policy-list.vue')
const seaView = () => import('@/views/sea-burial/sea-view.vue')
const seaIndex = () => import('@/views/sea-burial/sea-index.vue')
const seaBurial = () => import('@/views/sea-burial/sea-burial.vue')
const companyProfile = () => import('@/views/about-us/company-profile.vue')
const staffPresence = () => import('@/views/about-us/staff-presence.vue')
const onlineCall = () => import('@/views/call-center/online-call.vue')
const personalView = () => import('@/views/personal-center/personal-view.vue')
const personalCenter = () => import('@/views/personal-center/personal-center.vue')
const personalSchedule = () => import('@/views/personal-center/personal-schedule.vue')
const personalBack = () => import('@/views/personal-center/personal-back.vue')
const historicalChanges = () => import('@/views/personal-center/historical-changes.vue')


const routes = [
  {path: '*', component: notFind},
  {path: '/', name: 'index', meta: {title: '沈阳海葬-首页', footerTop: '-222px', select: 'index'}, component: index},
  {path: '/search', name: 'search', meta: {title: '搜索结果页'}, component: search},
  {path: '/news', name: 'news', meta: {select: 'news'}, component: news,
    children: [
      {path: 'news-list', name: 'news-list', meta: {title: '沈阳海葬-新闻资讯',  select: 'news'}, component: newsList}
    ]
  },
  {path: '/policy', name: 'policy', meta: {select: 'policy'}, component: policy,
    children: [
      {path: 'policy-list', name: 'policy-list', meta: {title: '沈阳海葬-政策法规', select: 'policy'}, component: policyList}
    ]
  },
  {path: '/sea', name: 'sea-view', meta: {select: 'sea'}, component: seaView,
    children: [
      {path: 'sea-index', name: 'sea-index', meta: {title: '沈阳海葬-海葬服务', select: 'sea'}, component: seaIndex},
      {path: 'sea-burial', name: 'sea-burial', meta: {title: '海葬服务-服务报名', select: 'sea'}, component: seaBurial}
    ]
  },
  {path: '/about/company', name: 'company-profile', meta: {title: '沈阳海葬-公司介绍', select: 'about'}, component: companyProfile},
  {path: '/about/presence', name: 'staff-presence', meta: {title: '沈阳海葬-员工风采', select: 'about'}, component: staffPresence},
  {path: '/personal-view', name: 'personal-view', component: personalView,
    children: [
      {path: 'personal-center', name: 'personal-center', meta: {title: '个人中心-完善信息', auth: true}, component: personalCenter},
      {path: 'personal-schedule', name: 'personal-schedule', meta: {title: '个人中心-办件进程', auth: true}, component: personalSchedule},
      {path: 'personal-back', name: 'personal-back', meta: {title: '个人中心-反馈回复', auth: true}, component: personalBack},
      {path: 'historical-changes', name: 'historical-changes', meta: {title: '个人中心-历史修改记录', auth: true}, component: historicalChanges}
    ]
  },
  {path: '/call/online', name: 'online-call', meta: {title: '沈阳海葬-在线客服', select: 'call'}, component: onlineCall},
  ...module
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PAGE_CONTEXT,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: to.meta.scrollTop || 0 };
    }
  },
  routes
});

const { back, replace } = router;

router.back = function() {
  this.isBack = true;
  back.call(router)
};
router.replace = function(...args) {
  this.isReplace = true
  replace.call(router, ...args)
};

router.beforeEach(async(to, from, next) => {
  // util.cookies.set('TICKET_0abb7fa5cffc45c08f1c6e7dfdaf2b54_432321')
  const { title, footerTop } = to.meta
  const token = util.cookies.get()
  document.title = title || process.env.VUE_APP_NAME
  footerTop ? $store.commit('updateFooterTop', footerTop) : $store.commit('updateFooterTop', '0px');
  if (token && token !== 'undefined') {
    if (_.isEmpty($store.state.user.userInfo)) {
      await $store.dispatch('getCurrentUser')
      console.log('router update vuex')
    }
  } else {
    if (_.isEmpty($store.state.user.orgInfo)) {
      await $store.dispatch('getAboutUs')
    }
  }
  if (to.matched.some(r => r.meta.auth)) {
    // 这里暂时将cookie里是否存有token作为验证是否登录的条件
    // 请根据自身业务需要修改
    if (token && token !== 'undefined') {
      next()
    } else {
      // 强制登录,false关闭close唯一关闭入口
      Logindialog({visible: false})
      next()
    }
  } else {
    next()
  }
});

export default router
