export default ({ service }) => ({
  website: {
    /**
     * 获取首页新闻资讯列表
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getRecommendColumnName(data) {
      return service.get('/website-api/recommend/columnName', {params: data});
    },

    /**
     * 获取导航栏
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getNavColumn(data) {
      return service.get('/website-api/column/navColumn', {params: data});
    },

    /**
     * 海葬报名方向介绍
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getContentSeaName(data) {
      return service.get('/website-api/content/seaName', {params: data});
    },

    /**
     * 获取资讯详情
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getContentDetail(data) {
      return service.get('/website-api/content/detail', {params: data});
    },

    /**
     * 获取轮播模块
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getContentBanner(data) {
      return service.get('/website-api/content/banner', {params: data});
    },

    /**
     * 获取公告模块
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getContentType(data) {
      return service.get('/website-api/content/type', {params: data});
    },

    /**
     * 获取列表加栏目结构
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getContentColumn(data) {
      return service.get('/website-api/contentColumn/content', {params: data});
    },

    /**
     * 获取关于我们
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getOneContent(data) {
      return service.get('/website-api/content/oneContent', {params: data});
    },

    /**
     * 获取单位信息
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getCommonPartnerDetail(data) {
      return service.get('/website-api/partner/partnerDetail', {params: data});
    },

    /**
     * 新增留言
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    postNewInteraction(data) {
      return service.post('/website-api/interaction/newInteraction', data);
    },

    /**
     * 海葬届数查询
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getFuneralWebList(data) {
      return service.get('/website-api/funeral/webList', {params: data});
    },

    /**
     * 户籍地列表查询
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getFuneralWebLocationList(data) {
      return service.get('/website-api/funeral/webLocationList', {params: data});
    },

    /**
     * 海葬预约申请
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    postFuneralWebSave(data) {
      return service.post('/website-api/funeral/webSave', data);
    },

    /**
     * 往期公告（最新公告去第一条）（员工风采）
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getContentColumnName(data) {
      return service.get('/website-api/content/columnName', {params: data});
    },

    /**
     * 获取海葬预约列表
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getSeaFuneralAppointmentList(data) {
      return service.get('/website-api/funeral/seaFuneralAppointmentList', {params: data});
    },

    /**
     * 海葬预约（删除）
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    detSeaFuneralCancel(data) {
      return service.post('/website-api/funeral/cancel', data);
    },

    /**
     * 海葬预约（取消）
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    SeaupdateStatus(data) {
      return service.post('/website-api/funeral/cancelStatus', data);
    },

    /**
     * 海葬预约详情
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getFuneralDetail(data) {
      return service.get('/website-api/funeral/detail', {params: data});
    },

    /**
     * 修改预约海葬信息
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    postFuneralWebUpdate(data) {
      return service.post('/website-api/funeral/webUpdate', data);
    },

    /**
     * 用户信息完善
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    postUserUpdate(data) {
      return service.post('/website-common/rbac/user/perfect', data);
    },

    /**
     * 搜索内容
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    searchContent(data) {
      return service.get('/website-api/content/searchContent', {params: data});
    },

    /**
     * 获取服务流程
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getServeProcess(data) {
      return service.get('/website-api/content/serveProcess', {params: data});
    },

    /**
     * 获取留言列表
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getQueryInteraction(data) {
      return service.get('/website-api/interaction/queryInteraction', {params: data});
    },

    /**
     * 获取海葬名人
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getRecommendRecomHall(data) {
      return service.get('/website-api/recommend/recomHall', {params: data});
    },

    /**
     * 查询海葬修改记录
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getFuneralRecord(data) {
      return service.get('/website-api/funeral/record', {params: data});
    },

    /**
     * 获取首页员工相册
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getFuneralStaffShow(data) {
      return service.get('/website-api/recommend/staffShow', {params: data});
    },

    /**
     * 获取关于我们员工相册
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getFuneralstaffDetail(data) {
      return service.get('/website-api/content/staffDetail', {params: data});
    }

  }
})
