
import './login.scss'

import QRCode from 'qrcodejs2'
import util from "@/utils/util";

export default {
  data: () => ({
    uuId: null,
    visible: false,
    timer: null // 定时器
  }),
  created() {
    this.uuId = util.generateUUID();
  },
  mounted() {
    this.$nextTick(() => {
      this.qrCode('bound-qrcode');
    })
  },
  destroyed() {
    clearInterval(this.timer); //终止轮询
  },
  methods: {
    //生成码
    qrCode(elem) {
      document.getElementById(elem).innerHTML = '';
      let path = `http://weixin.syhz.org.cn/funeral/code-login/?uuid=${ this.uuId}`
      let qrcode = new QRCode(elem, {
        width: 150,
        height: 150,
        colorDark : "#000000",
        colorLight : "#ffffff",
        typeNumber:4,
        correctLevel : QRCode.CorrectLevel.H
      });
      qrcode.clear();
      qrcode.makeCode(path)
      this.pollingCode()
    },
    pollingCode() {
      let _this = this;
      let CountDown = () =>{
        //setTimeout延迟此处做异步请求,类似接口
        let parmas = {
          yxappname: process.env.VUE_APP_MP_NAME,
          code: _this.uuId
        };
        _this.$api.system.pollingCode(parmas)
          .then( dates => {
            dates ? _this.logon(dates) : '';
          });
      };
      _this.timer = setInterval(CountDown,3000) //3秒轮询一次
    },
    logon(dates) {
      this.$store.commit('updateUserInfo', dates);
      this.$store.commit('updateToken', util.cookies.get());
      this.$message({
        type: 'success',
        message: '登录成功!'
      });
      this.$bus.$emit('fromLogin');
      this.onClose()
      //后端写入cooking
    },
    onClose() {
      clearInterval(this.timer); //终止轮询
      this.close()
      //this.$el.parentNode.removeChild(this.$el)
    },
    _renderClose() {
      if (this.visible) {
        return (
          <img class="login-close" onClick={() => {
            this.onClose()
          }} src={require('../../assets/images/dialog-login-close.png')}/>
        )
      }
    }
  },
  render() {
    return (
      <div class="xgj-dialog__wrapper">
        <transition name="fade" appear>
          <div class="modal-overlay"></div>
        </transition>
        <transition name="pop" role="dialog" appear>
          <div class="xgj-login">
            <div class="login-dialog">
              <div class="login-top">
                <img class="login-tips" src={require('../../assets/images/dialog-login-qrCode.png')} />
                {this._renderClose()}
              </div>
              <div class="login-center">
                <div class="login-center-title"><span>沈阳海葬</span></div>
                <div class="login-top-title">
                  <span></span>
                  <i>享移动端数据互通</i>
                  <span></span>
                </div>
                <div class="login-center-code">
                  <i id="bound-qrcode"></i>
                </div>
              </div>
              <div class="login-bottom">
                <div class="login-bottom-title">
                  <span></span>
                  <span>扫码快捷同步</span>
                  <span></span>
                </div>
                <div class="login-bottom-content">
                  <img src={require('../../assets/images/qrcode-wx-chat.png')} />
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    )
  }
}
