import api from '@/api'
import { errorCreate, parse } from '@/api/tools'

const $api = api

const seaModules = {
  state: {
    navList: [],
    seaBasic: {},
    seaArea: [],
    footerTop: '0px',
    maxPeople: 0
  },
  getters: {
    getMaxPeople: state => {
      if (state.maxPeople >= 20) {
        return 20
      } else {
        return state.maxPeople
      }
    }
  },
  mutations: {
    updateSeaBasic(state, basic) {
      state.seaBasic = basic
    },
    updateSeaArea(state, area) {
      state.seaArea = area
    },
    updateFooterTop(state, footerTop) {
      state.footerTop = footerTop
    },
    updateNavList(state, navList) {
      state.navList = navList
    },
    updateMaxPeople(state, maxPeople) {
      state.maxPeople = maxPeople
    }
  },
  actions: {
    async getSeaArea(context) {
      await $api.website.getFuneralWebLocationList()
        .then( dates => {
          context.commit('updateSeaArea', dates.data)
        }).catch( err => {
           //errorCreate(`户籍地列表查询: ${err}`)
        })
    },
    async getNavColumn(context) {
      await $api.website.getNavColumn({partnerId: process.env.VUE_APP_LIMIT_AREA_ID})
        .then( dates => {
          context.commit('updateNavList', dates)
        }).catch( err => {
          //errorCreate(`获取导航栏: ${err}`)
        })
    }
  }
}

export default seaModules
