export default ({ service }) => ({
  worship: {
    /**
     * 获取祭拜记录
     * @param data
     * @returns {Promise<AxiosResponse<T>>}
     */
    getWorships(data) {
      return service.get('/api/worship/list', {params: data});
    },
  }
})
